<template>
  <div id="qywx_bind"></div>
</template>

<script>
import WwLogin from '@/utils/wwLogin-1.2.5'
import { uuid } from '@/utils/uuid'
export default {
  name: 'Bind',
  components: {},
  props: {},
  data() {
    return {
			loginState: sessionStorage.getItem('state'),
		}
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loginState = uuid().toLowerCase()
      sessionStorage.setItem('state', this.loginState)
      this.$nextTick(() => {
        new WwLogin({
          id: 'qywx_bind',
          appid: process.env.VUE_APP_QYWX_CORP_ID,
          agentid: process.env.VUE_APP_QYWX_AGENT_ID,
          redirect_uri: encodeURI(window.location.protocol + process.env.VUE_APP_DOMAIN_NAME + '/account/settings/Security'),
          state: this.loginState,
          href: '',
          lang: 'zh',
        })
      })
    },
  },
}
</script>

<style scoped>
</style>