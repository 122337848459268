<template>
  <div>
    <a-alert message="请输入您的账户密码以确认解除企业微信绑定" type="info" class="message" show-icon />
    <a-form :form="form">
      <a-form-item>
        <a-input-password
          autocomplete="off"
          size="large"
          placeholder="请输入密码"
          v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' }]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input-password>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import md5 from 'md5'
import { unbindQYWX } from '@/api/user'
// import store from '@/store'
export default {
  name: 'Unbind',
  components: {},
  props: {
    userId:{
      type:Number,
      require:true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {},
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            const password = md5(values.password).toUpperCase()
            unbindQYWX({ password: password, userId: this.userId }).then(() => {
              this.$message.success('解除绑定成功')
              resolve(true)
            })
          }
        })
      })
    },
  },
}
</script>

<style scoped>
.message {
  margin-bottom: 20px;
}
</style>