<template>
  <a-list itemLayout="horizontal" :dataSource="data" v-if="!loading">
    <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
      <a-list-item-meta>
        <a slot="title">{{ item.title }}</a>
        <span slot="description">
          <span class="security-list-description">{{ item.description }}</span>
          <span v-if="item.value"> : </span>
          <span class="security-list-value">{{ item.value }}</span>
        </span>
      </a-list-item-meta>
      <template v-if="item.actions">
        <a slot="actions" @click="item.actions.callback">{{
          item.actions.title
        }}</a>
      </template>
    </a-list-item>
    <a-list-item>
      <a-list-item-meta>
        <span slot="title">企业微信绑定</span>
        <span slot="description">
          <span
            class="security-list-description"
            v-if="wechatUserId && wechatUserId !== ''"
            >已绑定</span
          >
          <a class="security-list-description" @click="handleBind" v-else
            >未绑定</a
          >
        </span>
      </a-list-item-meta>
      <template>
        <a-button
          slot="actions"
          @click="handleUnbind"
          v-if="wechatUserId && wechatUserId !== ''"
          >解除绑定</a-button
        >
        <a-button slot="actions" @click="handleBind" v-else
          >绑定企业微信</a-button
        >
      </template>
    </a-list-item>
  </a-list>
</template>

<script>
import PasswordForm from "../components/PasswordForm";
import Unbind from "../components/Unbind";
import Bind from "../components/Bind";
import { getUserInfo, bindAfterLogin } from "@/api/user";
import store from "@/store";

export default {
  data() {
    return {
      data: [
        {
          title: "账户密码",
          description: "账户密码修改",
          value: "",
          actions: {
            title: "修改",
            callback: this.changePasswordCallback,
          },
        },
        // { title: '密保手机', description: '已绑定手机', value: '138****8293', actions: { title: '修改', callback: () => { this.$message.success('This is a message of success') } } },
        // { title: '密保问题', description: '未设置密保问题，密保问题可有效保护账户安全', value: '', actions: { title: '设置', callback: () => { this.$message.error('This is a message of error') } } },
        // { title: '备用邮箱', description: '已绑定邮箱', value: 'ant***sign.com', actions: { title: '修改', callback: () => { this.$message.warning('This is message of warning') } } },
        // { title: 'MFA 设备', description: '未绑定 MFA 设备，绑定后，可以进行二次确认', value: '', actions: { title: '绑定', callback: () => { this.$message.info('This is a normal message') } } }
      ],
      wechatUserId: null,
      loginState: sessionStorage.getItem("state"),
      code: this.$route.query.code || "",
      callbackState: this.$route.query.state || "",
      loading: false,
      userId: null,
    };
  },
  created() {
    if (this.callbackState !== "") {
      //如果能拿到callbackState,说明是已经扫码完成了
      if (this.callbackState == this.loginState) {
        bindAfterLogin({ code: this.code, platform: "ORIGIN" }).then(() => {
          this.$message.success("绑定成功");
          this.loadData();
        });
      } else {
        //拿到的state与设定的state不同，校验不通过
        this.$notification["error"]({
          message: "错误",
          description: "企业微信登录校验不通过",
          duration: 4,
        });
      }
    } else {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      getUserInfo().then((res) => {
        this.wechatUserId = res.data.wechatUserId;
        this.userId = res.data.id;
        this.loading = false;
      });
    },
    changePasswordCallback() {
      this.$dialog(
        PasswordForm,
        {
          on: {
            ok: () => {
              store.dispatch("Logout").then(() => {
                this.$router.push({
                  path: "/user/login",
                });
              });
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "编辑",
          width: 700,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleBind() {
      this.$dialog(
        Bind,
        {},
        {
          footer: "",
          width: 350,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleUnbind() {
      this.$dialog(
        Unbind,
        {
          userId: this.userId,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "解除绑定",
          width: 400,
          centered: true,
          maskClosable: false,
        }
      );
    },
  },
};
</script>

<style scoped>
</style>
