<template>
  <a-form :form="form">
    <a-form-item label="旧密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input-password placeholder="请输入旧密码" v-decorator="['oldPassword']" />
    </a-form-item>
    <a-form-item label="新密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input-password placeholder="请输入新密码" v-decorator="['newPassword']" />
    </a-form-item>
    <a-form-item label="请确认密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input-password placeholder="请再次输入新密码" v-decorator="['newPasswordConfirm']" />
    </a-form-item>
  </a-form>
</template>

<script>
import { changePassword } from '@/api/user'
import md5 from 'md5'

export default {
  name: 'TaskForm',
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
    }
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            if (values.newPassword !== values.newPasswordConfirm) {
              this.$message.error('两次输入密码一致')
              return
            }
            if (!/^\w{6,30}/.test(values.newPassword)) {
              this.$message.error('新密码不符合要求')
              return
            }
            let formData = {
              oldPassword: md5(values.oldPassword).toUpperCase(),
              newPassword: md5(values.newPassword).toUpperCase(),
            }
            changePassword(formData).then(() => {
              this.$message.success('更新成功')
              resolve(true)
            })
          }
        })
      })
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true)
      })
    },
  },
}
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
